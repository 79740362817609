<template>
  <sub-page>

    <portal to="admin_support_action">
      <ws-button @click="filters.completed = !filters.completed" outlined :label="!filters.completed ? 'Відкрити виконані' : 'Відкрити поточні'"></ws-button>
    </portal>

    <div v-if="!filters.completed && itemsFilteredNew.length > 0">
      <h3 class="mb-2 wsDARKER mt-5">Нові надходження: {{ itemsFilteredNew.length }}</h3>

      <v-sheet :style="`border : 1px solid ${wsBACKGROUND}`" max-height="300px" class="overflow-y-auto pa-3">
        <ws-data-table
            no-header
            :items="itemsFilteredNew"
            :headers="headersNew"
            :row-action="openRequest"
        >
          <template #item.date="{item}">
            <h5 class="wsDARKLIGHT text-no-wrap">{{ MONTH_DAY_TIME(item.date , true  ) }}</h5>
          </template>

          <template #item.manager_group="{item}">
            <h5 class="wsACCENT text-no-wrap">{{ managerGroup[item.manager_group] || '' }}</h5>
          </template>

          <template #item.preview="{item}">
            <div class="d-flex align-center">
              <v-icon :color="wsATTENTION" class="mr-2" v-if="item">mdi-bug</v-icon>
              <h5 class="wsDARKER" :class="[{'font-weight-regular' : item.status !== 0}]">{{ item.preview }}</h5>
            </div>

          </template>

          <template #item.action="{item}">
            <ws-button height="28" @click="takeRequest(item)" outlined label="Взяти"></ws-button>

          </template>

        </ws-data-table>
      </v-sheet>
    </div>


    <h3 class="mt-5 mb-2 wsDARKER" >Запити в роботі: {{ itemsFilteredSelf.length }}</h3>
    <v-sheet :style="`border : 1px solid ${wsBACKGROUND}`" max-height="300px" class="overflow-y-auto pa-3">
      <ws-data-table
        no-header
        :items="itemsFilteredSelf"
        :headers="headers"
        :row-action="openRequest"
    >
      <template #item.date="{item}">
        <h5 class="wsDARKLIGHT text-no-wrap">{{ MONTH_DAY_TIME(item.date , true  ) }}</h5>
      </template>

      <template #item.manager_group="{item}">
        <h5 class="wsACCENT text-no-wrap">{{ managerGroup[item.manager_group] || '' }}</h5>
      </template>

      <template #item.preview="{item}">
        <div class="d-flex align-center">
          <v-icon :color="wsATTENTION" class="mr-2" v-if="item">mdi-bug</v-icon>
          <h5 class="wsDARKER" :class="[{'font-weight-regular' : item.status !== 0}]">{{ item.preview }}</h5>
        </div>

      </template>

    </ws-data-table>
    </v-sheet>


    <template #dialog>

      <admin-support-sidebar
          v-if="displaySidebar"
          @complete="handleRequestCompletion"
          @update="handleUpdateRequest"
          v-model="displaySidebar"
          :entity="selectedRequest"
          :uuid="selectedRequestUuid"
          :managers-select="managersSelect"
      />

    </template>
  </sub-page>
</template>

<script>
import entitiesFunctions from "@/mixins/entitiesFunctions";
import adminSupportSidebar from "@/components/pages/adminDashboard/support/UI/adminSupportSidebar";
import {mapActions} from "vuex";

export default {
  name: "managerTechSupportReequests",
  components : {
    adminSupportSidebar
  },
  mixins : [entitiesFunctions],
  data() {
    return {
      items : [],
      managerGroup : {
        developers : "Розробники",
        support : "Підтримка",
        sales : "Продажі"
      },
      selectedRequest : {},
      selectedRequestUuid : null,
      displaySidebar : false,
      managersSelect : [],
      filters : {
        completed : false,
        manager : true
      }
    }
  },
  computed : {
    itemsFilteredNew() {
      let items = this.itemsFiltered
      return items.filter(el => !el.manager_id )
    },
    itemsFilteredSelf() {
      let items = this.itemsFiltered
      return items.filter(el => !!el.manager_id )
    },
    itemsFiltered() {
      let items = this.COPY(this.items)

      return items
    },
    headersNew() {
      let items = this.COPY(this.headers)
      items.push(
          { value : 'action' , width : 10 }
      )
      return items
    },
    headers() {
      let items = [
        { text : 'Дата' , value : "date" , width : 10 },
        { text : 'Запит' , value : "preview" , sortable : false },
        { text : 'Менеджер' , value : "manager_name" , width: 10 },
        { text : 'Відділ' , value : "manager_group" , width: 10 },
        { text : 'Школа' , value : "business_name" , width: 200 }
      ]

      if ( this.filters.completed) {
        items.push(   { text : 'Статус' , value : "resolution" , width: 10 })
      }

      return items

    }
  },
  watch : {
    filters : {
      handler() {
        this.initPage()
      },
      deep : true
    }
  },
  methods : {
    ...mapActions('support' , ["GET_REQUESTS_ADMIN" , "UPDATE_REQUEST" ]),

    async takeRequest(item) {
      let data = {
        uuid : item.uuid,
        manager_id : this.$store.state.auth.userID
      }
      let result = await  this.UPDATE_REQUEST(data)
      if ( !result ) {
        return this.ERROR(this.$store.state.ajax.error)
      }
      this.handleUpdateRequest( result)
    },

    async initPage() {
      let result = await this.GET_REQUESTS_ADMIN(this.filters)
      if ( !result ) {
        return this.ERROR
      }
      this.items = result.requests
      this.managersSelect = result.managers_select
    },

    handleUpdateRequest(item) {
      let index = this.items.findIndex(el => el.uuid === item.uuid )
      if ( index === -1 ) {
        return
      }
      this.items[index] = item
      if ( item.manager_id !== this.$store.state.auth.userID ) {
        this.items = this.items.filter(el => el.uuid !== item.uuid  )
      } else {
        this.items = this.COPY(this.items)
      }

    },
    handleRequestCompletion() {
      let index = this.items.findIndex(el => el.uuid === this.selectedRequestUuid )
      if ( index === -1 ) {
        return
      }
      this.items = this.items.filter(el => el.uuid !== this.selectedRequestUuid )
      this.displaySidebar = false
      this.selectedRequest = {}
      this.selectedRequestUuid = null
    },
    openRequest(item) {
      this.selectedRequestUuid = item.uuid
      this.selectedRequest = this.COPY(item)
      this.displaySidebar = true
    }
  },
  mounted() {
    this.initPage()
  }
}
</script>

<style scoped>

</style>