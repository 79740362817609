var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('sub-page',{scopedSlots:_vm._u([{key:"dialog",fn:function(){return [(_vm.displaySidebar)?_c('admin-support-sidebar',{attrs:{"entity":_vm.selectedRequest,"uuid":_vm.selectedRequestUuid,"managers-select":_vm.managersSelect},on:{"complete":_vm.handleRequestCompletion,"update":_vm.handleUpdateRequest},model:{value:(_vm.displaySidebar),callback:function ($$v) {_vm.displaySidebar=$$v},expression:"displaySidebar"}}):_vm._e()]},proxy:true}])},[_c('portal',{attrs:{"to":"admin_support_action"}},[_c('ws-button',{attrs:{"outlined":"","label":!_vm.filters.completed ? 'Відкрити виконані' : 'Відкрити поточні'},on:{"click":function($event){_vm.filters.completed = !_vm.filters.completed}}})],1),(!_vm.filters.completed && _vm.itemsFilteredNew.length > 0)?_c('div',[_c('h3',{staticClass:"mb-2 wsDARKER mt-5"},[_vm._v("Нові надходження: "+_vm._s(_vm.itemsFilteredNew.length))]),_c('v-sheet',{staticClass:"overflow-y-auto pa-3",style:(("border : 1px solid " + _vm.wsBACKGROUND)),attrs:{"max-height":"300px"}},[_c('ws-data-table',{attrs:{"no-header":"","items":_vm.itemsFilteredNew,"headers":_vm.headersNew,"row-action":_vm.openRequest},scopedSlots:_vm._u([{key:"item.date",fn:function(ref){
var item = ref.item;
return [_c('h5',{staticClass:"wsDARKLIGHT text-no-wrap"},[_vm._v(_vm._s(_vm.MONTH_DAY_TIME(item.date , true )))])]}},{key:"item.manager_group",fn:function(ref){
var item = ref.item;
return [_c('h5',{staticClass:"wsACCENT text-no-wrap"},[_vm._v(_vm._s(_vm.managerGroup[item.manager_group] || ''))])]}},{key:"item.preview",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex align-center"},[(item)?_c('v-icon',{staticClass:"mr-2",attrs:{"color":_vm.wsATTENTION}},[_vm._v("mdi-bug")]):_vm._e(),_c('h5',{staticClass:"wsDARKER",class:[{'font-weight-regular' : item.status !== 0}]},[_vm._v(_vm._s(item.preview))])],1)]}},{key:"item.action",fn:function(ref){
var item = ref.item;
return [_c('ws-button',{attrs:{"height":"28","outlined":"","label":"Взяти"},on:{"click":function($event){return _vm.takeRequest(item)}}})]}}],null,false,578860427)})],1)],1):_vm._e(),_c('h3',{staticClass:"mt-5 mb-2 wsDARKER"},[_vm._v("Запити в роботі: "+_vm._s(_vm.itemsFilteredSelf.length))]),_c('v-sheet',{staticClass:"overflow-y-auto pa-3",style:(("border : 1px solid " + _vm.wsBACKGROUND)),attrs:{"max-height":"300px"}},[_c('ws-data-table',{attrs:{"no-header":"","items":_vm.itemsFilteredSelf,"headers":_vm.headers,"row-action":_vm.openRequest},scopedSlots:_vm._u([{key:"item.date",fn:function(ref){
var item = ref.item;
return [_c('h5',{staticClass:"wsDARKLIGHT text-no-wrap"},[_vm._v(_vm._s(_vm.MONTH_DAY_TIME(item.date , true )))])]}},{key:"item.manager_group",fn:function(ref){
var item = ref.item;
return [_c('h5',{staticClass:"wsACCENT text-no-wrap"},[_vm._v(_vm._s(_vm.managerGroup[item.manager_group] || ''))])]}},{key:"item.preview",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex align-center"},[(item)?_c('v-icon',{staticClass:"mr-2",attrs:{"color":_vm.wsATTENTION}},[_vm._v("mdi-bug")]):_vm._e(),_c('h5',{staticClass:"wsDARKER",class:[{'font-weight-regular' : item.status !== 0}]},[_vm._v(_vm._s(item.preview))])],1)]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }